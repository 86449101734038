<!--
License: Apache 2.0
  Source:

  Usage:
      <svg>
        <use href="#carbon-light-filled"></use>
      </svg>
-->
<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>

    <symbol viewBox="0 0 32 32" id="carbon-language"><path fill="currentColor" d="M18 19h6v2h-6zm0-4h12v2H18zm0-4h12v2H18zm-4 10v-2H9v-2H7v2H2v2h8.215a8.6 8.6 0 0 1-2.216 3.977A9.3 9.3 0 0 1 6.552 23H4.333a10.9 10.9 0 0 0 2.145 3.297A14.7 14.7 0 0 1 3 28.127L3.702 30a16.4 16.4 0 0 0 4.29-2.336A16.5 16.5 0 0 0 12.299 30L13 28.127A14.7 14.7 0 0 1 9.523 26.3a10.3 10.3 0 0 0 2.729-5.3zm-2.833-8h2.166L8.75 2H6.583L2 13h2.166L5 11h5.334zM5.833 9l1.834-4.4L9.5 9z" /></symbol>
    <symbol viewBox="0 0 32 32" id="carbon-light-filled"><path fill="currentColor" d="M15 2h2v5h-2zm6.688 6.9l3.506-3.506l1.414 1.414l-3.506 3.506zM25 15h5v2h-5zm-3.312 8.1l1.414-1.413l3.506 3.506l-1.414 1.414zM15 25h2v5h-2zm-9.606.192L8.9 21.686l1.414 1.414l-3.505 3.506zM2 15h5v2H2zm3.395-8.192l1.414-1.414L10.315 8.9L8.9 10.314zM16 10a6 6 0 1 0 6 6a6 6 0 0 0-6-6"/></symbol>

    </defs>
  </svg>
</template>

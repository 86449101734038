<!-- src/components/icons/TablerSprites.vue -->

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <symbol
        viewBox="0 0 24 24"
        id="tabler-border-corner-ios">
        <path
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4 20c0-6.559 0-9.838 1.628-12.162a9 9 0 0 1 2.21-2.21C10.162 4 13.44 4 20 4" />
      </symbol>
      <symbol
        viewBox="0 0 24 24"
        id="tabler-border-corner-pill">
        <path
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4 20v-5C4 8.925 8.925 4 15 4h5" />
      </symbol>
      <symbol
        viewBox="0 0 24 24"
        id="tabler-border-corner-rounded">
        <path
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4 20V10a6 6 0 0 1 6-6h10" />
      </symbol>
      <symbol
        viewBox="0 0 24 24"
        id="tabler-border-corner-square">
        <path
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4 20V5a1 1 0 0 1 1-1h15" />
      </symbol>

      <symbol
        viewBox="0 0 24 24"
        id="tabler-border-corner-pill">
        <path
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4 20v-5C4 8.925 8.925 4 15 4h5" />
      </symbol>
      <symbol
        viewBox="0 0 24 24"
        id="tabler-border-corner-rounded">
        <path
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4 20V10a6 6 0 0 1 6-6h10" />
      </symbol>
      <symbol
        viewBox="0 0 24 24"
        id="tabler-border-corner-square">
        <path
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4 20V5a1 1 0 0 1 1-1h15" />
      </symbol>
    </defs>
  </svg>
</template>

<!-- src/components/closet/SecretSkeleton.vue -->

<script setup lang="ts">

</script>

<template>
  <!-- Header/Title Placeholder -->
  <div class="h-8 w-1/3 rounded-lg bg-gray-200 dark:bg-gray-700"></div>

  <!-- Main Content Box -->
  <div class="rounded-lg border border-gray-200 p-6 dark:border-gray-700">
    <!-- Secret Info Line -->
    <div class="mb-4 h-6 w-2/3 rounded-lg bg-gray-200 dark:bg-gray-700"></div>

    <!-- Form/Content Area -->
    <div class="space-y-4">
      <div class="h-12 rounded-lg bg-gray-200 dark:bg-gray-700"></div>
      <div class="h-12 rounded-lg bg-gray-200 dark:bg-gray-700"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
</script>

<template>
  <div class="flex flex-col items-center text-center">
    <h3 class="font-base mb-2 text-gray-600 dark:text-gray-100 sm:mx-12 sm:block md:mx-2">
      {{ $t('web.homepage.tagline1') }}
    </h3>
    <p class="mx-12 mb-6 text-base italic text-gray-400 dark:text-gray-400 sm:mx-2">
      {{ $t('web.homepage.tagline2') }}
    </p>
  </div>
</template>
